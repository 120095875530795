import { createStore } from "solid-js/store";
import { createCVInfoInputs } from "../form";
import { FormPageProps } from "../model";
import { Cmp, Mod } from ":mods";
import { Show, splitProps } from "solid-js";
import { updateShowExhibition } from "../apis/show-exhibition";

export function CVInfo(props: FormPageProps) {
  const [local, others] = splitProps(props, ["stage_id", "data"]);
  const FormInputs = createCVInfoInputs();
  const [cvdata, setCvData] = createStore({
    key: "box-05",
    action: 0,
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    const values = FormInputs.Actions.getValuesSnakecase();

    if (values.upload_cv) {
      setCvData("action", 1);
    }
    const formData = new FormData();
    formData.append("data", JSON.stringify(cvdata));
    formData.append("cv", values.upload_cv[0]);
    console.log("JSON.stringify(experienceData)", JSON.stringify(cvdata));
    await updateShowExhibition(local.stage_id, formData).then((message) => {
      console.log(message, " :message");
      Cmp.Alert.actions.pushToast({
        type: "success",
        message: "CV Uploaded successfully",
      });
    });
  };

  return (
    <main class="flex flex-col items-center w-full justify-between">
      <section class="flex flex-col gap-40px w-full pb-80px pt-40px">
        <form class="flex flex-col gap-20px ">
          <p class="pt-2 font-bold">Upload Your CV</p>

          <div class="flex flex-col gap-10px items-center">
            <FormInputs.UploadCV.Label title="">
              <FormInputs.UploadCV.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.UploadCV.Label>

            <FormInputs.UploadCV.InputFile
              accept=".pdf"
              classes={{
                container:
                  "flex flex-col justify-center items-center bg-inherit border-2 border#p border-dashed text-14px px-2.5 py-50px w-630px",
              }}
              elements={{
                input({ onInputClicked, viewer_data, drag }) {
                  return (
                    <div
                      onclick={onInputClicked}
                      class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                    >
                      <i class="icon-local:upload-icon w-29.5px h-19.6px" />
                      <p class="ms-2px cursor-pointer">
                        <Show when={!drag} fallback={"Drop Files"}>
                          Drag files here or <span class="underline">choose a file</span>
                        </Show>
                      </p>
                      <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                    </div>
                  );
                },
              }}
            />
          </div>

          <div class="flex justify-end">
            <FormInputs.Actions.Button
              class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-175px h-44px text#n-50  h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
              onclick={onSubmit}
              statusValid={"Save and Close"}
              statusInvalid={"Save and Close"}
            />
          </div>
        </form>
      </section>
    </main>
  );
}
